$background: #000;
$outline: #eee;
$gold: #CAA243;
$outlineFocus: #CAA243;
$error: red;
$borderRadius: 10px;

$mobile: 769px;

$minHeight: 86vh;
$borderH: 7vh;
$borderW: 7vw;

$borderHMobile: 2vh;
$borderWMobile: 5vw;

$bodyFont: 'NB_Grotesk', monospace;
$fancyFont: 'Canela', serif;
$boldFont: 'Druk_Wide', sans-serif;

@import "~video-react/styles/scss/video-react";

@font-face {
  font-family: 'Canela';
  src: url('./fonts/Canela-Light-Web.woff2') format('woff2'),
      url('./fonts/Canela-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NB_Grotesk';
  src: url('./fonts/nb_grotesk_pro_mono_regular-webfont.woff2') format('woff2'),
      url('./fonts/nb_grotesk_pro_mono_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Druk_Wide';
  src: url('./fonts/DrukWide-Heavy-Web.woff2') format('woff2'),
      url('./fonts/DrukWide-Heavy-Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}


html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: $bodyFont;
  background: #fff;
  text-transform: uppercase;
}

body {
  font-feature-settings: "liga", "kern";
}

/* resets */ 
* {
  box-sizing: border-box;
}

button, input {
  font-family: $boldFont;
  background: none;
  border: 0;
  font-size: inherit;
  outline: none;
}

button, .inputlabel, .a-button {
  background-color: $gold;
  color: #000;
  padding: 14px 40px;
  cursor: pointer;
  text-transform: uppercase;
  line-height: 1;
  transition: 300ms all;
  text-decoration: none;

  &:hover {
    background: black;
    color: $gold;
  }

  span {
    display: block;
    font-size: 32px;

    @media(max-width: $mobile) {
      font-size: inherit;
    }
  }
}

.a-button {
  font-family: $boldFont;
}

.flipped {
  background-color: black;
  color: $gold;

  &:hover {
    background: $gold;
    color: black;
  }
}

h1 {
  font-family: $fancyFont;
  font-size: 52px;
  line-height: 1;
  text-transform: uppercase;

  @media(max-width: $mobile) {
    font-size: 24px;
  }
}

h2 {
  font-family: $boldFont;
  font-size: 32px;
  line-height: 1;
  text-transform: uppercase;

  @media(max-width: $mobile) {
    font-size: 22px;
  }
  a {
    color: $gold;
  }
}

.strong {
  font-family: $boldFont;
  text-transform: uppercase;
}

.fancy {
  font-family: $fancyFont;
}

header {
  position: fixed;
  top: 0px;
  z-index: 100;
  /*background: #fff;*/
  width: 100%;
  height: $borderH;
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: center;

  @media(max-width: $mobile) {
    height: 40px;
    text-align: left;
    justify-content: left;
  }

  em {
    color: $gold;
  }

  path {
    fill: #000;
  }

  .logo {
    height: 80%;
    padding-left: 20px;

    @media(max-width: $mobile) {
      margin-left: 10px;
      padding-left: 0px;
    }
  }
  
  svg {
    height: 100%;
    display: block;
  }


  .header-links {
    position: absolute;
    right: 0px;
    height: 100%;
    display: flex;

    a {
      display: block;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0px 20px;

      &:nth-of-type(1) {
        padding: 0px 40px;

        @media(max-width: $mobile) {
          padding: 0px 10px;
        }
      }
    }
  }

  .header-left {
    position: absolute;
    left: $borderW;

    @media(max-width: $mobile) {
      left: $borderWMobile;
    }
  }

  .header-right {
    position: absolute;
    right: $borderW;
    @media(max-width: $mobile) {
      right: $borderWMobile;
    }
  }

  a {
    color: #000;
    transition: 300ms color;
    font-family: $boldFont;
    color: $gold;
    font-size: 12px;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }
}

.header-black {
  background: #000;

  path {
    fill: white;
  }

  a {
    color: $gold;

    &:hover {
      color: white;
    }

    &:nth-of-type(2) {
      font-family: $bodyFont;
      background: #fff;
      color: #000;

      &:hover {
        color: $gold;
      }

      @media(max-width: $mobile) {
        display: none;
      }
    }
  }
}

.header-white {
  background: #fff;
  path {
    fill: black;
  }

  a {
    color: black;
    &:hover {
      color: $gold;
    }

    &:nth-of-type(2) {
      font-family: $bodyFont;
      background: #000;
      color: #fff;

      &:hover {
        color: $gold;
      }

      @media(max-width: $mobile) {
        display: none;
      }
    }
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.full {
  height: 100vh;
}

.content {
  position: relative;
  z-index: 99;
  background: url(./img/marble.jpg) center center no-repeat #eee;
  background-size: cover;
  background-attachment: fixed;
  margin: $borderH 0 0 0;
  text-align: center;

  @media(max-width: $mobile) {
    margi-top: 20px;
  }

  .home-btn {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;

    @media(max-width: $mobile) {
      top: 0px;
      width: 100%;
    }
  }

  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
  }

  .asterisk {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 12px;
    z-index: 2;
  }

  .entry-list {
    min-height: $minHeight;
    padding-top: 10px;
    @media(max-width: $mobile) {
      padding-top: 50px;
    }
  }

  .section-wide {
    width: 80%;
    margin: 0 auto;

    max-width: 900px;

    @media(max-width: $mobile) {
      width: 95%;
      margin-top: 60px;
    }

  }

  .section {
    width: 80%;
    margin: 0 auto;

    @media(max-width: $mobile) {
      width: 95%;
    }

    &-recorder {
      min-height: $minHeight;
    }

    &-final {
      min-height: $minHeight;
      /*video {
        width: 600px;
        height: 600px;

        @media(max-width: $mobile) {
          width: 100vw;
          height: 100vw;
        }
      }*/

      &-wide-video {
        width: 800px;

        @media(max-width: $mobile) {
          width: 100vw;
        }
      }

      a {
        display: block;
      }
    
      &-video-elements {
        background: url(./img/spotlight.jpg) center center no-repeat #000;
        background-size: cover;
        padding-top: 60px;

        @media(max-width: $mobile) {
          padding-top: 80px;
        }

        .video {
          margin: 0px auto;
        }

        .video-buttons {
          width: 600px;
          margin: 5px auto;
          padding: 0px;

          a {
            &:hover {
              color: $gold;
            }
          }

          .side-by-side {
            display: flex;
            width: 50%;
            margin: 0px auto;
            padding: 20px 0px 100px 0px;
            div {
              width: 50%;
    
              a {
                transition: 300ms all;
                &:hover {
                  opacity: 0.5;
                }
              }
              img {
                height: 30px;
              }
            }
          }

          @media(max-width: $mobile) {
            width: 100%;
          }

          a {
            display: block;
          }
        }
      }
    }

    &-home {
      position: relative;
      z-index: 2;
      overflow: hidden;
      min-height: $minHeight;
    }
  }

  .gold-plant {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 45vw;
    z-index: 1;

    @media (max-width: $mobile) {
      width: 30vw;
    }
  }

  .section-left {
    width: 50%;
    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  .video {
    background: #000;
    width: 600px;
    height: 600px;
    position: relative;

    @media(max-width: $mobile) {
      width: 100%;
      height: inherit;
    }


    button {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .video-buttons {
    text-align: center;
    padding-left: 60px;

    a {
      color: #000;
      transition: 300ms all;
      &:hover {
        color: #666;
      }
    }
  }

  .countdown {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    z-index: 99999;
    font-size: 400px;
    color: $gold;
    font-family: $boldFont;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: 500ms fade-in forwards;
    opacity: 0;

    &-small {
      font-size: 22px;
      font-family: $bodyFont;
      text-transform: uppercase;
      color: #fff;
    }
  }
}

.three-d {
  width: 40vw;
  height: 100vh;
  user-select: none;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
}

.browse {
  margin-top: $borderH;

  @media(max-width: $mobile) {
    margin-top: $borderHMobile;
  }

  &-header {
    padding: 20px;
    background: url(./img/marble.jpg) center center no-repeat;
    background-size: cover;
    font-family: $fancyFont;
    text-align: center;
    font-size: 52px;
    text-transform: uppercase;
    position: relative;

    &-sub {
      font-size: 14px;
      font-family: $boldFont;
    }

    @media(max-width: $mobile) {
      font-size: 22px;
      padding: 20px;
      margin-top: 40px;
    }

    div {
      position: relative;
      z-index: 2;
    }

    img {
      position: absolute;
      left: 50%;
      display: block;
      height: 500px;
      z-index: 1;
      top: -10px;
      transform: translateX(-50%);
    }
  }
  
  &-searchbar {
    background: #ccc;
    padding: 20px 40px 30px 40px;
    position: relative;

    @media(max-width: $mobile) {
      padding: 30px 20px;
    }

    a {
      display: block;
      position: absolute;
      bottom: 7px;
      left: 40px;

      @media(max-width: $mobile) {
        left: 20px;
        bottom: 5px;
      }
    }
    
    div {
      margin: 0 auto;
      
      .clear {
        display: block;
        color: #666;
        padding-top: 10px;
        position: absolute;

        &-hide {
          opacity: 0;
        }
      }

      input {
        width: 100%;
        border-bottom: 3px solid #000;
        font-family: $boldFont;
        font-size: 22px;
        padding: 5px 0px;
        text-transform: uppercase;
        background: url(./img/search.svg) no-repeat right center;
        background-size: 20px;

        @media(max-width: $mobile) {
          font-size: 22px;
        }
        
      }
    }
  }

  .browse-grid {
    display: flex;
    

    @media(max-width: $mobile) {
      flex-wrap: wrap;
      flex-direction: row;
    }

    &-cell {
      flex: 0 0 25%;
      height: auto;
      background-position: center center;
      background-size: cover;

      @media(max-width: $mobile) {
        flex: 0 0 50%;
      }

      a {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        transition: 300ms all;
        &:hover {
          background: rgba(0,0,0,0.2);
        }

        .play-btn {
          position: absolute;
          top: 0px;
          left: 0px;
          background: url(./img/playImg.svg) center center no-repeat $gold;
          width: 40px;
          height: 40px;
          background-size: 15px;
  
          @media(max-width: $mobile) {
            width: 20px;
            height: 20px;
            top: 0px;
            left: 0px;
            background-size: 10px;
          }
        }

        .home-video-meta {
          left: 0px;
          bottom: 20px;

          .home-video-meta-sub {
            font-size: 13px;

            @media(max-width: $mobile) {
              font-size: 10px;
            }
          }

          .home-video-meta-title {
            font-size: 14px;

            @media(max-width: $mobile) {
              font-size: 12px;
            }
          }
        }
      }

      &:before {
        content:'';
        float:left;
        padding-top:100%;
      }  
    }
  }

  .search-results-title {
    text-align: center;
    padding: 20px 0px 0px 0px;
    
  }

  &-content {
    width: 80%;
    margin: 60px auto;
    position: relative;
    z-index: 3;

    display: flex;
    justify-content: space-between;

    @media(max-width: $mobile) {
      display: block;
      width: 95%;
    }

    .browse-nothing {
      width: 50%;
      margin: 0 auto;
      text-align: center;
      font-size: 32px;
      img {
        max-width: 100%;
      }
    }

    .cell-huh {
      width: 25%;
    }

    &-cell {
      position: relative;
      /*flex-basis: calc(50% - 0px);*/
      
      background: none;
      padding: 0px;
      margin: 0px;
      display: block;
      transition: 300ms all;
      text-decoration: none;
      color: #000;

      &:hover {
        background: #000;
        color: #fff;

        .company-name {
          color: $gold;
        }

        .presenter {
          color: #fff;
        }
      }

      .play-img {
        position: relative;
      }
      
      .play-btn {
        position: absolute;
        top: 0px;
        left: 0px;
        background: url(./img/playImg.svg) center center no-repeat $gold;
        width: 50px;
        height: 50px;
        background-size: 20px;

        @media(max-width: $mobile) {
          width: 40px;
          height: 40px;
          top: 0px;
          left: 0px;
          background-size: 10px;
        }
      }
      
      
      &-inner {
        margin: 20px;

        text-align: center;
        
        img {
          width: 100%;
          display: block;
        }
      }

      .gold {
        img {
          width: 100%;
        }
      }
    }
  }


  &-river {
    display: block;

   

    .browse-content-cell-inner {
      margin: 0;
      padding: 20px;

      @media(max-width: $mobile) {
        padding: 5px 0px;
      }
    }

    .play-btn {
      width: 30px;
      height: 30px;
      background-size: 15px;
    }

    img {
      max-width: 100%;
    }

    .playlist-title {
      font-size: 22px;
      font-family: $boldFont;
      text-align: center;

      @media(max-width: $mobile) {
        font-size: 18px;
      }
    }
  }

  .playlist-river {
    margin-bottom: 100px;
    position: relative;

    @media(max-width: $mobile) {
      margin-bottom: 40px;
    }

    .arrow-previous, .arrow-next {
      padding: 10px;
      margin: 0px;
      top: 44%;
      transform: translateY(-50%);
      background: #fff;

      &:hover {
        background: $gold;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    .arrow-previous {
      position: absolute;
      left: -40px;
    }

    .arrow-next {
      position: absolute;
      right: -40px;
    }
  }

  .playlist-slide {
    /*padding: 10px;*/
  }
}

.speech-page {
  background: url(./img/spotlight.jpg) center center no-repeat #000;
  background-size: cover;
  text-align: center;
  color: #fff;
  padding-top: calc(#{$borderH} + 20px);
  min-height: calc(100vh - #{$borderH});

  @media(max-width: $mobile) {
    min-height: inherit;
  }

  &-inner {
    width: 600px;
    margin: 0 auto;
    @media(max-width: $mobile) {
      width: 100%;
    }
  }

  &-wide {
    .speech-page-inner {
      width: 80%;
    }
  }

  .video-stack {
    margin: 0 auto;
  }

  .speech-info {
    padding-bottom: 100px;
    padding-top: 20px;

    h1, h2 {
      padding: 0px;
      margin: 10px 0px;
      line-height: 1;
    }

    .work-url {
      color: #ccc;
      padding: 20px 0px;
      display: block;
    }

    @media(max-width: $mobile) {
      padding-bottom: 20px;
      width: 95%;
      margin: 0 auto;
    }

    .side-by-side {
      display: flex;
      width: 100px;
      margin: 0px auto;
      padding: 20px 0px 100px 0px;
      div {
        width: 50%;

        a {
          transition: 300ms all;
          &:hover {
            opacity: 0.5;
          }
        }
        img {
          height: 30px;
        }
      }
    }
  }
}

.work-list {
  list-style: none;
  padding: 0px;
  margin: 0px 0px 40px 0px;

  li {
    margin-bottom: 20px;
    background: #fff;
    color: #999;
    border-bottom: 1px solid #eee;
    

    .video-list {
      background: #fff;
      padding: 20px;

      a {
        color: $gold;
      }
    }

    button {
      background: #fff;
      display: block;
      width: 100%;
      border: 0;
      border-radius: 0px;
      font-family: $bodyFont;
      font-size: 12px;
      padding: 40px;
      
      transition: 300ms all;

      @media (max-width: $mobile) {
        padding: 20px;
      }

      strong {
        display: block;
        font-size: 22px;
        color: #000;
        font-family: $boldFont;
        transition: 300ms all;
        margin-bottom: 10px;

        @media (max-width: $mobile) {
          font-size: 18px;
        }
      }

      &:hover {
        background-color: $gold;
        color: #333;

        strong {
          color: #fff;
        }
      }
    }
  }
}

.progress {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  display: none;

  &-cell {
    height: 5px;
    width: 40px;
    margin: 0px 5px;
    background: #999;

    &-on {
      background: #000;
    }
  }
}

.recorder {
  .record-video {
    width: 360px;
    height: 640px;
    background: #000;
  }

  .video-webcam video {
    width: 600px;
  }
  
  p {
    strong {
      font-family: $boldFont;
    }
  }

  .button-choose {
    margin-top: 40px;
    div {
      padding: 10px 0px;
    }
  }

  .recording {
    position: absolute;
    top: 14px;
    left: 10px;
    font-size: 32px;
    font-weight: bold;
    color: red;
    animation: 1s blink infinite;
    text-transform: uppercase;
  }

  .countdown-progress {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 10px;
    background: $gold;
    animation: 15s countdown forwards linear;
  }
}

input[type=file] {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputlabel {
  display: inline-block;
  font-family: $boldFont;
}

.inputfile:focus, .inputlabel:focus,
.inputlabel:hover {
 /* background-color: red;*/
}

.home {
  &-header {
    height: 100vh;
    background: /*url(./img/spotlight.jpg)*/ center center no-repeat #000;
    background-size: cover;
    color: #fff;
    text-align: center;

    @media(max-width: $mobile) {
      height: inherit;
    }

    .party-banner {
      background: $gold;
      position: absolute;
      top: 0px;
      width: 100%;
      left: 0px;
      color: #fff;
      background: #000;
      padding: 10px;
      font-size: 12px;
      strong {
        color: $gold;
        font-family: $boldFont;
        
      }
      a {
        color: $gold;
        font-family: $boldFont;
      }
    }

    .welcome-text {
      font-size: 12px;
      text-align: center;
      padding-bottom: 20px;
    }

    &-foreground {
      position: relative;
      z-index: 2;
      width: 65%;
      color: #000;
      margin-left: 35%;

      /*background: rgba(0,0,0,0.85);*/
      padding: 20px;

      .logo-home {
        width: 500px;

        @media(max-width: $mobile) {
          width: 240px;
        }
      }

      @media(max-width: $mobile) {
        width: 95%;
        margin: 0px auto;
        padding: 20px 0px 40px 0px;

        button {
          width: 100%;
        }
      }

      .strong-smaller {
        font-size: 16px;
        line-height: 24px;

        @media(max-width: $mobile) {
          font-size: 10px;
          line-height: 18px;
        }
      }
    }

    .fancy {
      font-size: 42px;
    }

    .fancy-text {
      font-family: $fancyFont;
      font-size: 22px;
      margin: 10px auto;
      color: $gold;
      background: #000;
      display: inline-block;
    }

    .strong {
      font-size: 82px;
      color: $gold;
    }

    .blackout {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.2);
      display: none;
    }

    &-buttons {
      margin: 0 auto;
      padding-top: 0px;

      @media(max-width: $mobile) {
        button {
          padding: 14px;
        }

        padding-top: 0px;
      }

      .p {
        font-size: 12px;
        strong {
          font-size: 22px;
          display: block;
          font-family: $boldFont;
          padding: 2px 0px;

          @media(max-width: $mobile) {
            font-size: 16px;
          }
        }
        padding-bottom: 20px;
      }

      .a-link {
        button {
          background: #000;
          color: $gold;

          &:hover {
            background: $gold;
            color: #000;
          }
        }
      }

      button {
        width: 80%;

        @media(max-width: $mobile) {
          width: 100%;
        }
      }

      @media(max-width: $mobile) {
        padding-top: 0px;
        width: inherit;
      }
    }
  }

  &-opening {
    background: url(./img/marble.jpg) center center no-repeat;
    background-size: cover;

    video {
      height: 512px;
      width: 512px;
      background: #000;
    }
  }

  &-section-bar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    transform: translateY(100%);
    transition: 500ms all;
    z-index: 9999;
   

    &-inner {
      /*
      display: flex;
      flex-wrap: nowrap;*/
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-visible {
      transform: translateY(0%);
      &-gradient {
        position: fixed;
        right: 0px;
        bottom: 0px;
        width: 50px;
        height: 44px;
        background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1));
        z-index: 3;
      }
    }

    &-section-cell {
      background: url(./img/marble.jpg) center center #fff;
      
      display: inline-block;
      height: 68px;
    }

    &-cell {
      background: #000;
      color: #fff;
      display: inline-block;
      height: auto;
      flex: 0 0 auto;

      padding: 20px 30px;
      transition: 500ms all;
      text-align: left;

      span {
        font-family: $bodyFont !important;
        font-size: 12px;
      }

      button { 
        background: #000;
        color: $gold;
      }

      &-active {
        background: $gold;
        color: #000;

        button {
          background: $gold;
          color: #000;
        }
      }
    }
  }
}

.home-section-grid-intro {
  background: url(./img/marble.jpg) center center no-repeat #fff;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  h2 {
    font-family: $fancyFont;
    font-size: 42px;
  }
}

.home-video-meta {
  position: absolute;
  bottom: 60px;
  left: 20px;

  @media(max-width: $mobile) {
    left: 5px;
    bottom: 10px;
  }

  &-type {
    background: $gold;
    color: #000;
    font-size: 14px;
    font-family: $fancyFont;
    display: inline-block;
    padding: 2px;
    margin-bottom: 5px;
  }

  &-sub {
    background: #000;
    color: #fff;
    display: inline-block;
    padding: 2px 5px;
    font-size: 22px;
    font-family: $bodyFont;
  }

  &-title {
    background: #000;
    color: $gold;
    font-family: $boldFont;
    display: inline-block;
    padding: 2px 5px;
    font-size: 22px;
  }
}

.video-trigger {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: pointer;
}

.home-section-full {
  padding: 60px 0px;
  background: #ccc;

  .home-video-wrap {
    width: 600px;
    height: 600px;
    background: #000;
    margin: 0 auto;
    position: relative;

    @media(max-width: $mobile) {
      width: 100%;
      height: auto;
    }

    &-wide {
      height: inherit;
      width: 80%;

      @media(max-width: $mobile) {
        width: 100%;
      }
    }
  }
}

.home-section-grid {
  display: flex;
  flex-wrap: wrap;
  @media(max-width: $mobile) {
    display: block;
  }

  .home-section-grid-intro,
  .video-stack {
    flex: 0 50%;

    img {
      max-width: 100%;
    }

    &:before {
      content:'';
      float:left;
      padding-top:100%;
    }

    .video-react-video {
      display: block;
    }
  }
  
}

/* form stuff */
.form {
  text-align: center;
  button, input {
    display: inline-block;
    margin: 10px 0px;
    transition: 300ms all;

    @media(max-width: $mobile) {
      font-size: 12px;
      span {
        font-size: 26px;
      }
    }
  }

  &-help {
    font-size: 12px;
    color: #666;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    a {
      color: $gold;
    }

    @media(max-width: $mobile) {
      width: 95%;
    }
  }

  .button-disabled {
    color: rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }

  input[type=text] {
    text-transform: uppercase;
    font-size: 32px;
    width: 100%;
    padding: 10px 0px;
    background: #fff;
    border: 2px solid $outline;
    text-align: center;

    @media(max-width: $mobile) {
      font-size: 22px;
    }
    
    &:focus {
      border: 2px solid $outlineFocus;
    }
  }

  .error {
    color: $error;
    font-style: italic;
    font-size: 12px;
  }
}

footer {
  padding: 40px;
  background: #000;
  color: #fff;

  a {
    color: #fff;
    transition: 300ms all;

    &:hover {
      color: $gold;
    }
  }

  .footer-inner {
    width: 80%;
    margin: 0 auto;
    display: flex;

    @media(max-width: $mobile) {
      width: inherit;
      display: block;
      text-align: center;
    }
  }

  .footer-left, .footer-right {
    width: 50%;

    @media(max-width: $mobile) {
      width: 100%;
    }
  }

  .footer-left {
    .copyright {
      padding-top: 20px;
      font-size: 12px;
      color: #999;
    }
    img {
      width: 100px;
    }
  }

  .footer-right {
    font-size: 12px;
    
    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;

      li {
        float: left;
        margin: 3px 20px;
        @media(max-width: $mobile) {
          float: inherit;
        }
      }
      
      &::after {
        content: "";
        clear: both;
        display: table;
      }
    }

    .footer-social {
      padding-bottom: 20px;
      ul {
        
        @media(max-width: $mobile) {
          width: 200px;
          margin: 0 auto;
        }
      }

      li {
        float: left;
        margin: 0px 20px;
        
        @media(max-width: $mobile) {
          float: left;
          width: 50px;
          margin: 20px 0px;
        }
      }

      a {
        transition: 300ms all;

        &:hover {
          opacity: 0.5;
        }
      }
      img {
        width: 20px;
        height: 20px;
      }

    }
  }
}


.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(255,255,255,0.8);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;

  &-inner {
    background: #fff;
    padding: 10px;
    text-transform: uppercase;
    border: 5px solid $gold;

    span {
      animation: 1s blink infinite;
    }
  }

  img {
    width: 200px;
    display: block;
  }
}
/* animations */

.animation1 {
  transform: translateY(100px);
  opacity: 0;

  animation: 1s animIn 0ms forwards;
}

.animation2 {
  transform: translateY(100px);
  opacity: 0;

  animation: 1s animIn 250ms forwards;
}

.animation3 {
  transform: translateY(100px);
  opacity: 0;

  animation: 1s animIn 500ms forwards;
}

@keyframes animIn {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes countdown {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.wait-bar {
  margin-top: 10px;
  height: 20px;
  background: rgba(0, 0, 0, 0.1);
  position: relative;

  &-inner {
    position: absolute;
    width: 20%;
    height: 100%;
    background: $gold;
    left: 0px;
    animation: 2s pong infinite linear;
  }
}

@keyframes pong {
  0% {
    left: 0%;
  }

  50% {
    left: 80%;
  }

  100% {
    left: 0%;
  }
}

.video-stack {
  background: #fff;
  position: relative;

  @media(max-width: $mobile) {
   
  }

  .video-react-big-play-button {
    display: none !important;
  }

  &-video {
    width: 600px;
    height: 600px;
    position: absolute;
    opacity: 0;
    transition: 300ms opacity;
    pointer-events: none;
    visibility: hidden;
  }

  .video-poster {
    width: 100%;
    display: block;
  }

  .video-first {
    position: relative;
  }

  .video-follow {
    top: 0px;
    left: 0px;
    position: absolute;
  }

  .video-current {
    opacity: 1;
    pointer-events: inherit;
    visibility: visible;
  }

  .video-controls {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    opacity: 1;
    transition: 300ms opacity;

    .video-button-inner {
      background: $gold;
      position: absolute;
      top: 0px;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      img {
        width: 30px;
        height: 30px;
      }
    }

    button {
      width: 100%;
      height: 100%;
      padding: 0px;
      margin: 0px;
      transform: translateX(0px);
      left: 0px;
      bottom: inherit;
      top: 0px;
      background: rgba(0,0,0,0);
      &:hover {
        background: rgba(0,0,0,0);

        @media(max-width: $mobile) {
          background: rgba(0,0,0,0);
        }
      }
    }
    
    &-playing {
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.home {
  .video-stack {
    height: 100%;
    background: #333;
  }
}

.home-video-linkout {
  position: absolute;
  top: 28px;
  right: 28px;
  &:hover {
    opacity: 0.5;
  }
  img {
    width: 20px;
  }
}

.playbar {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 10px;
  background: rgba(0,0,0,0.25);

  &-inner {
    position: absolute;
    width: 0%;
    height: 10px;
    background: $gold;
    transition: 60ms width;
  }
}

.video-react .video-react-fullscreen-control,
.video-react .video-react-time-control,
.video-react .video-react-mute-control, 
.video-react .video-react-volume-menu-button,
.video-react .video-react-play-control {
  display: none !important;
}

.home, .speech-page { 
  .video-stack .video-controls {
    height: calc(100% - 30px);
  }

  
  
  .video-react {
    font-family: $bodyFont !important;
  }

  .video-react-control-bar {
    display: flex !important;
    background-color: rgba(0,0,0,0.7);
  }

  .video-react-paused {
    .video-react-control-bar {
      display: none !important;
    }
  }

  .video-react .video-react-slider {
    background-color: rgba(0,0,0,0.5) !important;
  }

  .video-react .video-react-load-progress div,
  .video-react .video-react-load-progress {
    background-color: rgba(0,0,0,1) !important;
  }

  .video-react .video-react-play-progress {
    background-color: $gold;
  }

  .video-react .video-react-slider:focus {
    box-shadow: none;
  }
}


.video-react .video-react-loading-spinner {
  border: 6px solid $gold;
}

.crop-gif {
  width: 200px;
  border: 5px solid #fff;
  box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.23);

  @media(max-width: $mobile) {
    width: 150px;
  }
}

.ReactModalPortal {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999999999;
}

.modal-inner {
  h3 {
    text-align: center;
    font-family: $fancyFont;
  }
  li {
    padding: 5px 0px;
  }
  .a-button { display: block; width: 100%; text-align: center; }
}

.close-btn {
  position: absolute;
  background: none;
  right: 5px;
  top: 5px;
  padding: 0px;
  margin: 0px;
}

.footer-pre {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  &-footer {
    background: #222;
    padding: 20px;
  }

  a {
    vertical-align: middle;
  }

  img {
    height: 20px;
    padding: 0px 20px;
    vertical-align: center;

    @media(max-width: $mobile) {
      height: 15px;
    }
  }
}

.footer-pre-page {
  background: #333;
  padding: 20px;
  color: #fff;

  @media(max-width: $mobile) {
    text-align: center;
    display: block;

    a {
      display: block;
      width: 100%;
      
      text-align: center;
      padding: 20px 0px;
    }
  }
}

.bg-black {
  background: #000;
}

.bg-room {
  background: url(./img/room.jpg) center bottom no-repeat #fff;
  background-size: cover;
}


.bg-marble {
  background: url(./img/marble.jpg) center center no-repeat #eee;
  background-size: cover;
}

.bg-spotlight {
  background: url(./img/spotlight.jpg) center center no-repeat #000;
  background-size: cover;
}


.browse-river-meta {
  margin-top: 10px;
  .presenter {
    font-size: 12px;
    color: #333;

    @media(max-width: $mobile) {
      font-size: 10px;
    }
  }
  .company-name {
    font-family: $boldFont;

    @media(max-width: $mobile) {
      font-size: 11px;
    }
  }
  
  .work-name {
    font-family: $boldFont;
  }
}

.webby-message-final {
  font-family: $fancyFont;
  font-size: 22px;
  padding-bottom: 10px;
}